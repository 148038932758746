<template lang="pug">
div.d-flex.justify-space-between
  div.width-121
    QrcodeVue(v-if="documentStatus?.verification_number" :value="qrCodePath" size="121" level="H" class="qrcode")
  div.document-header-title.mx-3 {{ $t('documentInfo.serviceName') }}
  div.text-end.document-header-status
    div.text-nowrap {{ $t('documentInfo.documentStatus') }}:
    div(:class="documentStatus.status.eng === 'Expired' ? 'error--text' : 'color-green'").fw-500 {{ documentStatus.status?.[langFields] }}
    div.text-nowrap {{ $t('documentInfo.on') }} {{  $moment(documentStatus.time, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') }}
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'

export default {
  props: { documentStatus: { type: Object, default: () => ({}) } },
  components: { QrcodeVue },
  data () { return { moment } },
  computed: { 
    ...mapState({ langFields: state => (state.app.lang === 'en') ? 'eng' : 'ukr' }),
    qrCodePath () {
      const currentPath = new URL(window.location)
      const currentLink = currentPath.origin.toString() + '/sailor/su/' + this.documentStatus?.verification_number
      return this.langFields === 'eng' ? currentLink + '/?lang=en' : currentLink
    }
  },
}
</script>